import React from 'react';
import format from 'date-fns/format';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';

import useLocale from '../hooks/useLocale';

const ForthcomingCard = ({ cardData, tableData, specialTimeReference = false }) => {
  const localizedUrl = tableData.isSpanish
    ? tableData.isEvening
      ? '/es/apply/evening/'
      : '/es/apply/'
    : '/apply/';
  const formattedLocalizedUrl = 'url' + localizedUrl.replace(/\//gi, '.') + 'index';
  const { enLocale } = useLocale({ matchPathName: '/es/' });

  return (
    <div className="c-fourth-course-card">
      <div className="c-row">
        <span className="c-title-16">
          <span className="hidden">
            {tableData.course || <FormattedMessage id="forthcomingCourses.course" />}:
          </span>
          {cardData.name?.text}
        </span>
      </div>
      <div className="c-row c-text-14 from-to">
        <span className="hidden">
          {tableData.from || <FormattedMessage id="forthcomingCourses.from" />}:
        </span>
        {!cardData.is_price_per_month
          ? specialTimeReference
            ? cardData.from.replace(/\d+/gi, '')
            : cardData.from
          : format(new Date(cardData.from), 'MMMM')}
      </div>
      <div className="c-row c-text-14 from-to">
        <span className="hidden">
          {tableData.to || <FormattedMessage id="forthcomingCourses.to" />}:
        </span>
        {!cardData.is_price_per_month
          ? specialTimeReference
            ? cardData.to.replace(/\d+/gi, '')
            : cardData.to
          : format(new Date(cardData.to), 'MMMM')}
      </div>
      <div className="c-row">
        <span className="c-title-24 price text-fw-600">
          <span className="hidden">
            {tableData.price || <FormattedMessage id="forthcomingCourses.price" />}:
          </span>
          <span>€{cardData.price}</span>
          {!cardData.is_price_per_month ? (
            ''
          ) : (
            <span className="period">{enLocale ? '/month' : '/mes'}</span>
          )}
        </span>
      </div>
      <div className="c-row bage">
        {cardData?.urgency_text?.text?.length > 0 && (
          <p className="places-available">{cardData?.urgency_text?.text}</p>
        )}
      </div>
      {/* <Link
        to={
          tableData.isSpanish
            ? tableData.isEvening
              ? '/es/apply/evening'
              : '/es/apply/'
            : '/apply/'
        }
        className="c-btn c-btn--red c-text-14"
      >
        {tableData.isSpanish ? 'Inscríbete ahora' : 'Apply Now'}
      </Link> */}
      <FormattedMessage id={formattedLocalizedUrl}>
        {(locale) => (
          <Link to={locale[0]} className="c-btn c-btn--red c-text-14">
            <FormattedMessage id="forthcomingCourses.btn.applyNow" />
          </Link>
        )}
      </FormattedMessage>
    </div>
  );
};

export default ForthcomingCard;
